import { priorFormat, countryCityFormat, phoneFormat } from "~/basePlugins/filters";

const DEFAULT_AVATAR = 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/globalDefaultAvatar.png'
export function confirmUserInfo(info, userId, locale) {
    let selfInfo = {}, otherInfo = {};
    if (userId == info.send_user_id) {
        selfInfo = {
            id: info.send_user_id,
            company_id: info.send_company_id,
            avatar: info.send_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.send_user_name_en,
                info.send_user_name_zh,
                locale
            ),
            company: priorFormat(
                info.send_company_name_en,
                info.send_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.send_job_title_en,
                info.send_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.send_company_country,
                info.send_company_region_en,
                info.send_company_region_zh,
                locale
            ),
            email: info.send_email,
            wechat: info.send_wechat,
            mobile: phoneFormat(info.send_mobile, info.send_area_code),
            videoInfo: info.send_user_video || [],
            facebook: info.send_facebook
        };
        otherInfo = {
            id: info.in_user_id,
            company_id: info.in_company_id,
            avatar: info.in_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.in_user_name_en,
                info.in_user_name_zh,
                locale
            ),
            company: priorFormat(
                info.in_company_name_en,
                info.in_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.in_job_title_en,
                info.in_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.in_company_country,
                info.in_company_region_en,
                info.in_company_region_zh,
                locale
            ),
            email: info.in_email,
            wechat: info.in_wechat,
            mobile: phoneFormat(info.in_mobile, info.in_area_code),
            videoInfo: info.in_user_video || [],
            facebook: info.in_facebook
        };
    } else {
        selfInfo = {
            id: info.in_user_id,
            company_id: info.in_company_id,
            avatar: info.in_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.in_user_name_en,
                info.in_user_name_zh,
                locale
            ),
            company: priorFormat(
                info.in_company_name_en,
                info.in_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.in_job_title_en,
                info.in_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.in_company_country,
                info.in_company_region_en,
                info.in_company_region_zh,
                locale
            ),
            email: info.in_email,
            wechat: info.in_wechat,
            mobile: phoneFormat(info.in_mobile, info.in_area_code),
            videoInfo: info.in_user_video || [],
            facebook: info.in_facebook
        };
        otherInfo = {
            id: info.send_user_id,
            company_id: info.send_company_id,
            avatar: info.send_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.send_user_name_en,
                info.send_user_name_zh,
                locale
            ),
            company: priorFormat(
                info.send_company_name_en,
                info.send_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.send_job_title_en,
                info.send_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.send_company_country,
                info.send_company_region_en,
                info.send_company_region_zh,
                locale
            ),
            email: info.send_email,
            wechat: info.send_wechat,
            mobile: phoneFormat(info.send_mobile, info.send_area_code),
            videoInfo: info.send_user_video || [],
            facebook: info.send_facebook
        };
    }
    return {
        selfInfo, otherInfo
    }
}
export function confirmUserInfoFree(info, userId, locale) {
    let selfInfo = {}, otherInfo = {};

    if (userId == info.user1_id) {
        selfInfo = {
            id: info.user1_id,
            company_id: info.user1_company_id,
            avatar: info.user1_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.user1_name_en,
                info.user1_name_zh,
                locale
            ),
            company: priorFormat(
                info.user1_company_name_en,
                info.user1_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.user1_job_title_en,
                info.user1_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.user1_company_country,
                info.user1_company_region_en,
                info.user1_company_region_zh,
                locale
            ),
            email: info.user1_email,
            wechat: info.user1_wechat,
            mobile: phoneFormat(info.user1_mobile, info.user1_area_code),
            videoInfo: info.user1_user_video || [],
            facebook: info.user1_facebook
        };
        otherInfo = {
            id: info.user2_id,
            company_id: info.user2_company_id,
            avatar: info.user2_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.user2_name_en,
                info.user2_name_zh,
                locale
            ),
            company: priorFormat(
                info.user2_company_name_en,
                info.user2_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.user2_job_title_en,
                info.user2_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.user2_company_country,
                info.user2_company_region_en,
                info.user2_company_region_zh,
                locale
            ),
            email: info.user2_email,
            wechat: info.user2_wechat,
            mobile: phoneFormat(info.user2_mobile, info.user2_area_code),
            videoInfo: info.user2_user_video || [],
            facebook: info.user2_facebook
        };
    } else {
        selfInfo = {
            id: info.user2_id,
            company_id: info.user2_company_id,
            avatar: info.user2_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.user2_name_en,
                info.user2_name_zh,
                locale
            ),
            company: priorFormat(
                info.user2_company_name_en,
                info.user2_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.user2_job_title_en,
                info.user2_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.user2_company_country,
                info.user2_company_region_en,
                info.user2_company_region_zh,
                locale
            ),
            email: info.user2_email,
            wechat: info.user2_wechat,
            mobile: phoneFormat(info.user2_mobile, info.user2_area_code),
            videoInfo: info.user2_user_video || [],
            facebook: info.user2_facebook
        };
        otherInfo = {
            id: info.user1_id,
            company_id: info.user1_company_id,
            avatar: info.user1_avatar || DEFAULT_AVATAR,
            name: priorFormat(
                info.user1_name_en,
                info.user1_name_zh,
                locale
            ),
            company: priorFormat(
                info.user1_company_name_en,
                info.user1_company_name_zh,
                locale
            ),
            job_title: priorFormat(
                info.user1_job_title_en,
                info.user1_job_title_zh,
                locale
            ),
            countryCity: countryCityFormat(
                info.user1_company_country,
                info.user1_company_region_en,
                info.user1_company_region_zh,
                locale
            ),
            email: info.user1_email,
            wechat: info.user1_wechat,
            mobile: phoneFormat(info.user1_mobile, info.user1_area_code),
            videoInfo: info.user1_user_video || [],
            facebook: info.user1_facebook
        };
    }
    return { selfInfo, otherInfo }
}

export function handleAdInfo(info, LOCALE) {
    let locale = LOCALE == 'en' ? '1' : '2';
    let adInfo = info.advertisement_info || [];
    let leftAd = adInfo.find((item) => {
        return item.position == 1 && item.language == locale;
    }) || {
        url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/defaultVideoChatAd.jpg",
    };
    let rightAd = adInfo.find((item) => {
        return item.position == 2 && item.language == locale;
    }) || {
        url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/defaultVideoChatAd.jpg",
    };
    let videoAd = adInfo.find(item => {
        return item.position == 3 && item.language == locale;
    }) || {}
     //{ url: '7e6a7aca43de430d834c858ab5d5' }
    return info = {
        leftAd,
        rightAd,
        videoAd
    };
}

export function formatSeconds(value, locale) {
    var theTime = parseInt(value); // 秒
    var middle = 0; // 分
    var hour = 0; // 小时
    if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
        }
    }
    if (locale == 'en') {
        var result = "" + parseInt(theTime) + " s ";
        if (middle > 0) {
            result = "" + parseInt(middle) + " m " + result;
        }
        if (hour > 0) {
            result = "" + parseInt(hour) + " h " + result;
        }
        return result;
    } else {
        var result = "" + parseInt(theTime) + " 秒 ";
        if (middle > 0) {
            result = "" + parseInt(middle) + " 分 " + result;
        }
        if (hour > 0) {
            result = "" + parseInt(hour) + " 时 " + result;
        }
        return result;
    }
}

export function confirmChatClass(type) {
    if (type == 'seats' || type == 'free' || type == 'miniBooth') {
        return 'free'
    } else {
        return 'order'
    }
}