<template>
  <div class="checkDeviceComponents">
    <div class="checkoutConcent" v-show="showCheckContent">
      <div class="checkoutTitle">
        <el-row>
          <el-col :span="4"><div style="padding: 5px"></div></el-col>
          <el-col
            :span="4"
            v-for="(it, index) in iconTitleList"
            :key="index"
            :class="{ titleCol: index == 0 }"
          >
            <div>
              <div class="title_row">
                <el-button
                  :type="it.isShow ? 'primary' : 'info'"
                  size="medium"
                  circle
                  style="cursor: auto"
                >
                  <i class="iconfont iconSize" :class="it.icon"></i>
                </el-button>
                <i
                  class="el-icon-warning title_disted"
                  v-if="it.isNormal == false"
                ></i>
                <i
                  class="el-icon-success title_success"
                  v-if="it.isSuccess && it.isNormal"
                ></i>
              </div>

              <div v-if="it.isShow && !it.type">
                <p class="fontColor">{{ it.name }}</p>
                <p class="fontColor">{{ $t("testing") }}</p>
                <!-- {{it.isShow&&!it.type ?it.name+$t('testing')+"...":it.name}} -->
              </div>
              <div v-else class="fontColor">{{ it.name }}</div>
            </div>
          </el-col>
          <el-col :span="4">&nbsp;</el-col>
        </el-row>
      </div>
      <div class="checkoutMain clearfix">
        <div
          v-for="(item, index) in checkoutList"
          :key="index"
          v-show="index == checkoutIndex"
        >
          <div v-if="item.title">
            <div class="mainLeft fl">
              <div v-if="index == 0">
                <div>{{ $t("camera") }}</div>
                <div class="camera" id="selfCheckWindow"></div>
              </div>
              <div v-if="index == 1">
                <div>{{ $t("loudspeaker") }}</div>
                <div class="audioMain">
                  <el-progress
                    :show-text="false"
                    :stroke-width="30"
                    :percentage="percentage"
                  ></el-progress>
                  <div class="play">
                    <span
                      v-if="audioPlayShow"
                      class="cursor"
                      @click="autoPlay(1)"
                    >
                      <i class="el-icon-video-pause"></i>
                      {{ $t("bePlaying") }}
                    </span>
                    <span v-else class="cursor" @click="autoPlay(1)">
                      <i class="el-icon-video-play"></i>
                      {{ $t("ClickPlaying") }}
                    </span>
                  </div>
                  <audio
                    id="audio"
                    src="https://s1.videocc.net/live-web-start/assets/media/audio-test.ff5c92d4.mp3"
                    :autoplay="checkoutIndex == 1"
                    @ended="overAudio"
                  ></audio>
                </div>
              </div>
              <div v-if="index == 2" class="microMain">
                <div>{{ $t("microphone") }}</div>
                <div class="micro">
                  <span
                    v-for="(its, index) in microList"
                    :class="{ microItem: its.micor }"
                    :key="index"
                  ></span>
                </div>
              </div>
            </div>
            <div
              class="mainRight fl"
              :class="LOCALE == 'en' ? 'mainRightE' : 'mainRightZ'"
            >
              <div>
                <p>{{ item.title }}</p>
                <div
                  v-for="(its, index) in item.itemList"
                  class="itsItem"
                  :key="index"
                >
                  {{ its.item }}
                </div>
                <!-- <div class="marginT">注意：{{item.attent}}</div> -->
              </div>
            </div>
          </div>
          <div class="setting tc" v-else>
            <el-table :data="settList" stripe size="mini">
              <el-table-column
                prop="name"
                :label="$t('inspectionItem')"
              ></el-table-column>
              <el-table-column prop="isNormal" :label="$t('inspectionResult')">
                <template slot-scope="scope">
                  <span :class="{ isNormal: scope.row.isNormal != 1 }">{{
                    scope.row.isNormal == 1
                      ? $t("checkSuccess")
                      : $t("checkErro")
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="checkboxGroup">
              <el-checkbox v-model="openVideo" :disabled="openVideoDisabled"
                >{{$t('openCreamer')}}</el-checkbox
              >
              <el-checkbox v-model="openAudio" :disabled="openAudioDisabled"
                >{{$t('openSpeaker')}}</el-checkbox
              >
            </div>
          </div>
          <div class="checkoutBtn">
            <div v-if="!item.title">
              <button
                class="btn_confirm"
                :class="hostType ? 'btn_confirm' : 'btn_confirm_disabled'"
                :disabled="!hostType"
                @click="toEnterInto"
              >
                {{ $t("clickToEnter") }}
              </button>
              <button class="btn_cencel" @click="againCencel(index)">
                {{ $t("checkBack") }}
              </button>
            </div>
            <div v-else>
              <button
                :class="isNormal ? 'btn_confirm' : 'btn_confirm_disabled'"
                :disabled="isNormal == false"
                @click="isConfirm(index)"
              >
                {{ item.btnLeft }}
              </button>
              <button class="btn_cencel" @click="isConfirm(index, 1)">
                {{ item.btnRight }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="browerNotSupport tc"
      v-show="showCheckContent && !isBrowersuppot"
    >
      <img
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/error_img.png"
        alt
      />
      <p class="tc">{{ $t("versionTooLow") }}</p>
      <p class="tc">{{ $t("recommendedUser") }}</p>
    </div>
  </div>
</template>

<script>
/*
事件：enterInto 
参数:三个设备是否可用  类型是数组(待定)；
作用：进入会议直播页面
 */
import { getOs, getBrowser } from "~/baseUtils/getLogInfo";
import "@/baseAssets/icon/iconfont.css";
export default {
  props: {
    meetingType: {
      type: String,
      default: "biunique",
    },
    hostId: {
      type: String || Number,
      default: null,
    },
    meetingInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      CHATAPPTD: "",
      chatListL: [],
      isNormal: true, //设备是否正常，true为正常，false为不正常
      hostType: false,
      audioPlayShow: true,
      percentage: 0,
      microtage: 10,
      iconTitleList: [
        {
          icon: "iconxingzhuang",
          key: "Camera",
          name: this.$t("camera"),
          isShow: true,
          isSuccess: false,
          isNormal: true, //设备是否正常，true为正常，false为不正常
        },
        {
          icon: "iconshengyin",
          key: "Speaker",
          name: this.$t("loudspeaker"),
          isShow: false,
          isSuccess: false,
          isNormal: true,
        },
        {
          icon: "iconyuyin",
          key: "Microphone",
          name: this.$t("microphone"),
          isShow: false,
          isSuccess: false,
          isNormal: true,
        },
        {
          icon: "iconshezhi",
          name: this.$t("testing"),
          type: 1,
          isShow: false,
        },
      ],
      settList: [],
      microList: [],
      checkoutIndex: 0,
      checkoutList: [
        {
          title: this.$t("cameraTitle"),
          itemList: [
            { item: this.$t("cameraItem1") },
            { item: this.$t("cameraItem2") },
            { item: this.$t("cameraItem3") },
          ],
          btnLeft: this.$t("lookLeft"),
          btnRight: this.$t("lookRight"),
        },
        {
          title: this.$t("loudTitle"),
          itemList: [
            { item: this.$t("loudItem1") },
            { item: this.$t("loudItem2") },
            { item: this.$t("loudItem3") },
          ],
          btnLeft: this.$t("hearLeft"),
          btnRight: this.$t("hearRight"),
        },
        {
          title: this.$t("micrTitle"),
          itemList: [
            { item: this.$t("micrItem1") },
            { item: this.$t("micrItem2") },
            { item: this.$t("micrItem3") },
          ],
          btnLeft: this.$t("lookLeft"),
          btnRight: this.$t("lookRight"),
        },
        {
          itemList: [
            { item: this.$t("camera") },
            { item: this.$t("loudspeaker") },
            { item: this.$t("microphone") },
          ],
        },
      ],
      videoDevice: {},
      audioDevice: {},
      showCheckContent: false,
      isBrowersuppot: true,
      openVideo: true,
      openAudio: true,
      openVideoDisabled: false,
      openAudioDisabled: false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    stratCheck() {
      this.handleQuery();
      this.checkBrower();
      // if (
      //   this.routerParams.type == "test" ||
      //   this.routerParams.type == "free"
      // ) {
      //   this.checkBrower();
      // } else {
      //   if (this.IS_LOGIN) {
      //     if (this.meetingType == "groupChat") {
      //       this.checkBrower();
      //     } else {
      //       this.checkAuth();
      //     }
      //   } else {
      //     this.$nextTick((res) => {
      //       this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
      //         waitTodo: () => {
      //           if (this.meetingType == "groupChat") {
      //             this.checkBrower();
      //           } else {
      //             this.checkAuth();
      //           }
      //         },
      //       });
      //     });
      //   }
      // }
    },
    checkAuth() {
      if (
        this.USER_ID == this.meetingInfo.send_user_id ||
        this.USER_ID == this.meetingInfo.in_user_id
      ) {
        this.checkBrower();
      } else {
        this.$router.push("/noAuth");
      }
    },
    checkBrower() {
      this.showCheckContent = true;
      let bool = AgoraRTC.checkSystemRequirements();
      if (bool) {
        this.getDevices();
        this.microData();
      } else {
        this.isBrowersuppot = false;
        try {
          this.$store.dispatch("baseStore/addchatLog", {
            live_id: this.routerParams.channel_id,
            user_id: this.routerParams.user_id,

            agenda_type: this.routerParams.type == "free" ? 2 : 1,
            data_info: JSON.stringify({
              3: "False",
              6: this.$moment().unix(),
            }),
          });
        } catch {}
      }
    },
    handleQuery() {
      let routerParams = this._decode(this.$route.query.parameter);
      routerParams.type =
        routerParams.chat_type == "seats" ||
        routerParams.chat_type == "free" ||
        routerParams.chat_type == "miniBooth"
          ? "free"
          : "order";
      this.routerParams = routerParams;
    },
    microData() {
      let arr = [];
      for (var i = 0; i < 12; i++) {
        let obj = {
          micor: false,
        };
        arr.push(obj);
      }
      this.microList = arr;
    },
    // 设备是否正常
    async isConfirm(index, type) {
      if (index == 0) {
        if (this.localStream) {
          this.localStream.stop();
          this.localStream.close();
        }
      } else if (index == 1) {
        clearInterval(this.interval);
        let audio = document.getElementById("audio");
        this.percentage = 0;
        audio.currentTime = 0;
        audio.pause();
      } else if (index == 2) {
        if (this.localAudio) {
          await this.localAudio.setEnabled(false);
          clearInterval(this.audioInterval);
          this.RTCclient.leave();
          //this.handleMediaStatus()
          // this.localStream.close();
          // this.RTCclient.leave();
          // this.RTCclient = null;
        }
      } else {
        if (this.localStream) {
          this.localStream.stop();
          this.localStream.close();
          this.RTCclient.leave();
          this.RTCclient = null;
          clearInterval(this.playInterval);
        }
      }
      if (this.checkoutIndex >= 0 && this.checkoutIndex < 3) {
        this.checkoutIndex++;
        this.setStatus(index, type);
        if (!type) {
          this.iconTitleList[index].isSuccess = true;
          this.iconTitleList[index].isNormal = 1;
        } else {
          this.iconTitleList[index].isNormal = 0;
          this.againCencel(index);
        }
      }
      if (
        index == 2 &&
        this.meetingType == "groupChat" &&
        this.hostId == this.USER_INFO.id
      ) {
        if (this.iconTitleList[0].isNormal || this.iconTitleList[2].isNormal) {
          this.hostType = true;
        } else {
          this.hostType = false;
        }
      } else {
        this.hostType = true;
      }
      this.handleMediaStatus();
    },
    //重新检测
    againCencel(index) {
      if (index == 3) {
        this.checkoutIndex = 0;
        this.hostType = false;
        this.setStatus(index);
        this.iconTitleList.forEach((it) => {
          it.isNormal = true;
          it.isSuccess = false;
        });
      }
    },
    // 设备状态
    setStatus(index, type) {
      this.iconTitleList[index].isShow = false;
      this.iconTitleList[this.checkoutIndex].isShow = true;
      this.getDevices(index);
      if (type == 1) {
        this.iconTitleList[index].isNormal = false;
      }
    },
    // 检测设备状态
    getDevices(index) {
      AgoraRTC.getDevices()
        .then((devices) => {
          switch (this.checkoutIndex) {
            case 0:
              let video = devices.find((item) => {
                return item.kind == "videoinput";
              });
              if (video) {
                this.videoDevice = video;
                this.initSDK();
              }
              this.isNormal = video ? true : false;
              break;
            case 1:
              // let audioout = devices.find((item) => {
              //   return item.kind == "audiooutput";
              // });
              this.isNormal = true;
              this.audioPlayShow = true;
              this.autoPlay();
              break;
            case 2:
              let audio = devices.find((item) => {
                return item.kind == "audioinput";
              });
              this.isNormal = audio ? true : false;
              this.isNormal = true;
              if (audio) {
                this.audioDevice = audio;
                this.initSDK();
              }
              break;
            case 3:
              this.settList = this.iconTitleList.filter((it) => it.type != 1);

            default:
          }
          this.iconTitleList[this.checkoutIndex].isShow = true;
        })
        .catch((e) => {
          console.log("get devices error!", e);
        });
    },
    // 检测设备是否正常
    async initSDK() {
      let streams = {};
      if (this.checkoutIndex == 0) {
        streams = {
          audio: false,
          video: true,
        };
      } else {
        streams = {
          audio: true,
          video: false,
        };
      }
      this.RTCclient = AgoraRTC.createClient({ mode: "rtc", codec: "h264" }); //创建视频客户端
      if (this.checkoutIndex == 0) {
        this.localStream = await AgoraRTC.createCameraVideoTrack();
        this.localStream.play("selfCheckWindow");
      }
      if (this.checkoutIndex == 2) {
        AgoraRTC.createMicrophoneAudioTrack()
          .then((e) => {
            this.audioLevelChange(e);
            this.localAudio = e;
          })
          .catch((e) => {
            console.log(e);
          });
      }

      // this.RTCclient.init("b640c804764a43e386803f9ec4ea3029", () => {
      //   this.localStream = AgoraRTC.createStream(streams);
      //   this.localStream.setVideoProfile("720p_1"); //设置视频属性
      //   this.localStream.setAudioProfile("high_quality"); //设置音频属性
      //   this.localStream.init(
      //     () => {
      //       this.notVideo = false;
      //       if (this.checkoutIndex == 0) this.localStream.play("selfWindow"); //显示本地视频
      //       if (this.checkoutIndex == 2) {
      //         this.audioLevelChange();
      //       }
      //     },
      //     (err) => {
      //       //stream对象初始化失败
      //       console.log("getUserMedia failed", err);
      //     }
      //   );
      // });
    },
    async audioLevelChange(val) {
      this.audioInterval = setInterval(() => {
        this.audioLevel = Math.round(val.getVolumeLevel() * 50);
        if (this.audioLevel > 12) this.audioLevel = 12;
        this.microList.forEach((it, index) => {
          if (this.audioLevel && index < this.audioLevel) {
            it.micor = true;
          } else {
            it.micor = false;
          }
        });
      }, 300);
    },
    // 扬声器开始播放
    autoPlay(val) {
      if (this.checkoutIndex == 1) {
        let audio = document.getElementById("audio");
        let addValue = 100 / audio.duration;
        let interval;
        if (val == 1) this.audioPlayShow = !this.audioPlayShow;
        if (this.audioPlayShow) {
          audio.play();
          this.interval = setInterval(() => {
            this.percentage += addValue;
          }, 1000);
        } else {
          audio.pause();
          clearInterval(this.interval);
        }
      }
    },
    // 扬声器播放完毕
    overAudio() {
      let audio = document.getElementById("audio");
      clearInterval(this.interval);
      this.percentage = 0;
      this.audioPlayShow = false;
      audio.pause();
      clearInterval(this.interval);
    },
    handleMediaStatus() {
      let video = this.iconTitleList[0].isNormal ? true : false;
      let audio = this.iconTitleList[2].isNormal ? true : false;
      this.openVideo = video;
      this.openAudio = audio;
      if (!video) {
        this.openVideoDisabled = true;
      }
      if (!audio) {
        this.openAudioDisabled = true;
      }
    },
    // 点击进入发射事件
    toEnterInto() {
      this.reportLog();
      let checkResult = {
        video: this.openVideo,
        audio: this.openAudio,
      };
      this.$emit("enterInto", checkResult);
    },
    reportLog() {
      let params = {
        isBrowersuppot: true,
        os: getOs(),
        browser: getBrowser().browserName + getBrowser().browserVer,
        video: this.videoDevice.label,
        audio: this.audioDevice.label,
      };
      this.$store.dispatch("baseStore/addchatLog", {
        live_id: this.routerParams.channel_id,
        user_id: this.routerParams.user_id,

        agenda_type: this.routerParams.type == "free" ? 2 : 1,
        data_info: JSON.stringify({
          1: params.video ? params.video : "",
          2: params.audio ? params.audio : "",
          3: "True",
          4: params.os,
          5: params.browser,
          6: this.$moment().unix(),
        }),
      });
    },
  },
};
</script>

<style scoped lang="less">
.checkDeviceComponents {
  .checkoutConcent,
  .browerNotSupport {
    width: 560px;
    height: 368px;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #fff;
    left: 50%;
    top: 50%;
    .checkoutTitle {
      width: 100%;
      height: 104px;
      padding: 15px 0 0px;
      background: #3e3e4e;
      text-align: center;
      color: #fff;
      .titleCol::after {
        content: "";
        position: absolute;
        width: 280px;
        top: 20px;
        left: 162px;
        border-bottom: 2px dashed #343448;
        // border-bottom: 2px dashed #343448;
        // color: #343448;
      }
      .title_row {
        position: relative;
        display: inline-block;
      }
      .title_disted {
        color: rgb(255, 91, 91);
        font-size: 16px;
        position: absolute;
        left: 25px;
        top: 25px;
      }
      .title_success {
        color: #1890ff;
        font-size: 16px;
        position: absolute;
        left: 25px;
        top: 25px;
      }
      .iconSize {
        font-size: 20px;
      }
    }
    .checkoutMain {
      padding: 25px 0;
      font-size: 12px;
      .mainLeft {
        padding: 0 32px;
        width: 50%;
        box-sizing: border-box;
        .audioMain {
          width: 192px;
          height: 32px;
          line-height: 32px;
          margin-top: 16px;
          position: relative;
          text-align: center;
          font-size: 12px;
          text-align: center;
          i {
            font-size: 14px;
            margin-right: 3px;
          }

          /deep/.el-progress-bar__outer {
            border-radius: 0;
            background-color: #366bee;
          }
          /deep/.el-progress-bar__inner {
            background-color: #2951b5;
            border-radius: 0;
          }
        }
        .play {
          position: absolute;
          top: 0;
          left: 50px;
          color: #fff;
        }
        .camera {
          width: 190px;
          height: 110px;
          border: 1px solid #cccccc;
          // margin: 10px 20px;
          margin: 16px 0;
        }
        .microMain {
          position: relative;
          /deep/.el-progress-bar__outer {
            border-radius: 0;
            background-color: #fff;
            height: 24px;
          }
          /deep/.el-progress-bar__inner {
            background-color: #0083f6;
            border-radius: 0;
            height: 24px;
          }
        }
        .micro {
          display: flex;
          position: absolute;
          width: 184px;
          height: 24px;
          margin-top: 16px;
          span {
            display: inline-block;
            width: 8px;
            height: 24px;
            background: #e5e6eb;
            border-radius: 4px;
            margin-right: 6px;
          }
        }
      }
      .mainRightE {
        padding: 0 10px;
      }
      .mainRightZ {
        padding: 26px 30px 0 30px;
      }
      .mainRight {
        width: 50%;
        min-height: 150px;
        border-left: 2px dashed rgba(0, 0, 0, 0.1);
        & > div > p {
          font-size: 14px;
          color: #366bee;
          margin-bottom: 10px;
        }
        .itsItem {
          line-height: 20px;
        }
        .marginT {
          margin-top: 20px;
          color: #333;
        }
      }
      // .setting {
      //   // padding: 20px;
      // }
    }

    .checkoutBtn {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 48px;
      line-height: 48px;
      width: 100%;
      text-align: center;
      font-size: 16px;
      button {
        height: 100%;
        width: 50%;
        padding: 0;
        outline: none;
        cursor: pointer;
        border: none;
        color: #666;
      }
      .btn_confirm {
        background: #1890ff;
        position: absolute;
        color: #fff;
        left: 0;
        &:hover {
          opacity: 0.9;
        }
      }
      .btn_confirm_disabled {
        position: absolute;
        left: 0;
        background-color: #bdbdbd;
        cursor: auto;
        color: #fff;
      }
      .btn_cencel {
        position: absolute;
        right: 0;
      }
      .btn_again {
        width: 100%;
        height: 100%;
        line-height: 48px;
        background: #1890ff;
        color: #fff;
      }
    }
  }
  .browerNotSupport {
    padding-top: 80px;
    z-index: 10;
    img {
      margin-bottom: 40px;
    }
    p {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}
.checkboxGroup {
  padding-top: 10px;
}
.microItem {
  background: #0083f6 !important;
}
.isNormal {
  color: red;
}
.fontColor {
  color: #fff;
  margin-top: 3px;
}
/deep/.el-button--info {
  color: #ffffff;
  background-color: #343448;
  border-color: #343448;
}
/deep/.el-table td,
/deep/.el-table th.is-leaf {
  text-align: center;
}
</style>