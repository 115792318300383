<template>
  <div class="whiteBoardComponents">
    <div id="whiteboardPanel" class="whiteboardPanel"></div>
    <div class="uploadWhiteTips" v-if="uploading">
      <i class="el-icon-loading"></i><span>正在上传文档</span>
    </div>
    <div v-if="!has_docs" class="whiteboardTips">
      <p><i class="el-icon-document-delete"></i></p>
      <p>{{ $t("noDocument") }}</p>
    </div>
    <div class="uploadDocBtn">
      <fileUpload
        :autoUpload="true"
        :accept="'.doc,.docx,.pdf,.ppt,.pptx'"
        ossPath="conferenceWhite/"
        @uploadCompleteOSS="uploadCompleteWhiteDocs"
        @startUploadOSS="startUploadWhiteDocs"
        class="fl"
      >
        <button id="buttonBtn"></button>
      </fileUpload>
    </div>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
let WHITEROOM = null; //白板房间承载对象，不可使用vue对象来承载
export default {
  components: { fileUpload },
  props: {
    whiteInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    user_id: {
      type: Number,
      default: 0,
    },
    has_docs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      WHITEAPPID: "mPi7QK-fEeqnOHc-hbMatA/RLWt4NX266ziUQ",
      uploading: false,
    };
  },
  mounted() {
    //this.initWHITESDK();
  },
  methods: {
    initWHITESDK() {
      this.WHITEclient = new WhiteWebSdk({
        appIdentifier: this.WHITEAPPID,
        handToolKey: " ",
        preloadDynamicPPT: true,
      });

      this.WHITEclient.joinRoom(this.whiteInfo)
        .then((room) => {
          WHITEROOM = room;
          //room.disableDeviceInputs = true;
          WHITEROOM.setMemberState({ currentApplianceName: "hand" });
          WHITEROOM.bindHtmlElement(document.getElementById("whiteboardPanel"));
          //room.disableCameraTransform = true;
          window.addEventListener("resize", () => {
            WHITEROOM.refreshViewSize();
          });
          WHITEROOM.scalePptToFit("immediately");
          this.handleWHITESDKEvent();
        })
        .catch((e) => {
          console.log(`~~~~~~~~~~~~~~`)
          console.log(e)
        });
    },
    uploadCompleteWhiteDocs(result) {
      this.handleCompleteUpload(result);
    },
    startUploadWhiteDocs() {
      this.uploading = true;
      WHITEROOM.cleanCurrentScene(false);
      this.handleBeginUpload();
    },
    handleUploadClick() {
      document.getElementById("buttonBtn").click();
    },
    handleWHITESDKEvent() {
      WHITEROOM.addMagixEventListener("DOCUSUPLOADSUCCESS", (payload) => {
        console.log(payload);
        this.$emit("docsUploadSuccess", payload.payload.user_id);
        //监听文档上传成功
        // console.log(payload);
        // if (!this.mobileInfo.isMobile) {
        //   if (!this.whiteAtTop) {
        //     this.videoAtTop = true;
        //   }
        // }
        // this.showAllInfo = false;
        // this.$nextTick(() => {
        //   this.fileBtnLoading = false;
        //   this.has_docs = true;
        //   this.uploadBtnText = this.$t("Uploaddocument");
        //   this.showDocBtns = true;
        // });
        // if (payload.payload.user_id == this.USERID) {
        //   this.isDocOwner = true;
        //   this.$store.dispatch("API_live/reportDocUpload", {
        //     live_id: this.RTCCHANNELID,
        //     has_docs: parseInt(this.USERID),
        //   });
        // } else {
        //   this.isDocOwner = false;
        // }
      });
      WHITEROOM.addMagixEventListener("DOCUSUPLOADBEGIN", (payload) => {
        this.$emit("docsUploadBegin", payload.payload.user_id);
        //监听文档开始上传
        // this.fileBtnLoading = true;
        // if (payload.payload.user_id != this.USERID) {
        //   this.uploadBtnText = this.$t("EachUploaddocument");
        // } else {
        //   this.uploadBtnText = this.$t("uploading");
        // }
      });
      WHITEROOM.addMagixEventListener("CLEARCURRENTDOCS", (payload) => {
        this.$emit("docsClearSuccess");
      });
      WHITEROOM.addMagixEventListener("CLEAROTHERSDk", (payload) => {
        if (payload.payload.user_id != this.user_id) {
          this.$emit("clearselfSDk");
        }
      });
    },
    async handleWhitepptConverter(url) {
      const pptConverter = this.WHITEclient.pptConverter(
        this.whiteInfo.roomToken
      );
      let pptResult = await pptConverter.convert({
        // 需要进行转换资源的网络地址，请确保可以正常访问
        url: url,
        // 转换类型
        kind: "static",
        // 转换进度监听
        onProgressUpdated: (progress) => {
          console.log(progress);
        },
        checkProgressInterval: 1500,
        checkProgressTimeout: 5 * 60 * 1000,
      });
      WHITEROOM.dispatchMagixEvent("DOCUSUPLOADSUCCESS", {
        user_id: this.user_id,
      });
      // WHITEROOM.cleanCurrentScene(false);
      WHITEROOM.removeScenes("/");
      WHITEROOM.putScenes(`/ppt`, pptResult.scenes);
      WHITEROOM.setScenePath(`/ppt/${pptResult.scenes[0].name}`);
      WHITEROOM.scalePptToFit("immediately");
      this.uploading = false;
    },
    pptPreviousStep() {
      WHITEROOM.pptPreviousStep();
    },
    pptNextStep() {
      WHITEROOM.pptNextStep();
    },
    getAllPage() {
      let allScenes = WHITEROOM.entireScenes();
      console.log(allScenes);
    },
    clearOtherSDk() {
      WHITEROOM.dispatchMagixEvent("CLEAROTHERSDk", {
        user_id: this.user_id,
      });
    },
    clearDocs() {
      //清除文档
      WHITEROOM.cleanCurrentScene(false);
      WHITEROOM.dispatchMagixEvent("CLEARCURRENTDOCS");
    },
    // 上传文件
    handleBeginUpload() {
      WHITEROOM.dispatchMagixEvent("DOCUSUPLOADBEGIN", {
        user_id: this.user_id,
      });
    },
    handleCompleteUpload(result) {
      this.handleWhitepptConverter(result.url);
    },
    handleWhiteFit() {
      this.$nextTick(() => {
        WHITEROOM.scalePptToFit("immediately");
      });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 780px) {
  @import "./less/whiteBoard_pc.less";
}
@media screen and (max-width: 780px) {
  @import "./less/whiteBoard_mobile.less";
}
</style>