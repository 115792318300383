import relyConfig from '~/baseConfig/rely'
export default class {
    RTMAPPID = relyConfig.agoraData;
    rtc = {
        client: null,
        channel: null
    }
    store = null;
    options = {
        channel_id: '',
        user_id: '',
        getHistory: true,
    };
    apis = {
        textTokenApi: '',
        historyMessageApi: ''
    };
    callBack = {};
    constructor(config, store, message, callBack) {
        let params = Object.assign({}, config);
        this.options.channel_id = params.channel_id + '0315';
        this.options.user_id = params.user_id + '';
        this.options.getHistory = params.getHistory ? true : false;
        this.apis.textTokenApi = params.apis && params.apis.textTokenApi || 'baseStore/getbiuniqueTalkToken';
        this.apis.historyMessageApi = params.apis && params.apis.historyMessageApi || 'baseStore/getRTMHistoryMsg'
        this.store = store;
        this.callBack = callBack || {};
        this.init()
    };
    async init() {
        this.rtc.client = AgoraRTM.createInstance(this.RTMAPPID);
        this.joinChannel();
        if (this.options.getHistory) {
            this.getHistoryMessage()
        }
    };
    async joinChannel() {
        try {
            let tokenResult = await this.store.dispatch(this.apis.textTokenApi, { user_id: this.options.user_id });
            if (tokenResult.success) {
                this.rtc.client.login({ token: tokenResult.data, uid: this.options.user_id }).then(() => {
                    this.rtc.channel = this.rtc.client.createChannel(this.options.channel_id);
                    this.handleRTMSDKEvent();
                    this.rtc.channel.join().then(res => {
                        console.log('加入聊天频道成功')
                    })
                })
            };
        } catch (e) {
            console.log(e)
        }
    }
    handleRTMSDKEvent() {                  //监听接收消息
        this.rtc.channel.on('ChannelMessage', ({ text }, senderId) => {
            this.callBack.reviceMessage && this.callBack.reviceMessage(text,senderId);
        })
    }
    sendRTMMessage(text) {
        this.rtc.channel.sendMessage({ text }, { enableHistoricalMessaging: true })
            .then(() => {
                this.callBack.sendMessageComplete && this.callBack.sendMessageComplete(text);
            })
            .catch((error) => {
                this.callBack.sendMessageFail && this.callBack.sendMessageFail();
            })
    }
    async getHistoryMessage() {
        let result = await this.store.dispatch(this.apis.historyMessageApi, {
            channel_id: this.options.channel_id,
            start_time: moment(moment().format('YYYYMMDD')).toISOString(),
            end_time: moment().toISOString(),
            limit: 50
        });
        if (result.success) {
            let data = result.data.sort((a, b) => {
                return a.ms - b.ms;
            })
            data.forEach((item) => {
                item.text = item.payload;
                if (item.src == this.options.user_id) {
                    item.self = true;
                } else {
                    item.other = true;
                }
            });
            this.callBack.historyMessageComplete && this.callBack.historyMessageComplete(data)
        }
        console.log(result)

    }
    quitSdk() {
        this.rtc.client.logout();
        this.rtc = {
            client: null,
            channel: null
        }
    }
}